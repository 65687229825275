import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { WebHeader } from '../../components/Header';
import { Link } from 'react-router-dom';
import { Card, Button, Collapse, Spinner } from 'react-bootstrap';
import '../../style.css';
import { Footer } from '../../components/Footer';
import InputMask from "react-input-mask";

const Stores = () => {
    const [storesList, setStoresList] = useState([]);
    const [expandedProvince, setExpandedProvince] = useState('Витебск');
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);
    const [firstProvince, setFirstProvince] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
        if (token) {
            setHasToken(true);
        }
    }, []);

    useEffect(() => {
        const fetchAllStores = async () => {
            try {
                const response = await axios.get('https://node-server.m-lavka.by/stores');
                setStoresList(response.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        fetchAllStores();
    }, []);

    const sortProvinces = (provinceA, provinceB) => {
        if (provinceA === 'Витебск' && provinceB !== 'Витебск') {
            return -1;
        } else if (provinceA !== 'Витебск' && provinceB === 'Витебск') {
            return 1;
        }
        return provinceA.localeCompare(provinceB);
    };

    const groupedStores = storesList.reduce((acc, store) => {
        if (!acc[store.province]) {
            acc[store.province] = [];
        }
        acc[store.province].push(store);
        return acc;
    }, {});

    useEffect(() => {
        if (Object.keys(groupedStores).length > 0) {
            setFirstProvince(Object.keys(groupedStores)[0]);
        }
    }, [groupedStores]);

    const handleProvinceToggle = (province) => {
        if (expandedProvince === province) {
            setExpandedProvince(''); // Если кликаем на уже открытый список, то сворачиваем его
        } else {
            // Если кликаем на закрытый список, то открываем его, только если stores.province === 'Витебск'
            if (province !== '') {
                setExpandedProvince(province);
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete('https://node-server.m-lavka.by/stores/' + id);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    };

    const styles = {
        deleteButton: {
            width: '100px',
            height: '40px',
            marginBottom: '10px',
        },
        editButton: {
            width: '100px',
            height: '40px',
            marginBottom: '10px',
        },
    };

    const sortedGroupedStores = Object.entries(groupedStores).sort(([provinceA], [provinceB]) =>
        sortProvinces(provinceA, provinceB)
    );

    return (
        <>
            <WebHeader />
            <h1 className="d-flex justify-content-center align-items-center text text-danger ">Наши магазины</h1>

            <div className="container row test">

                <div className="  mx-sm-5  col-xl-5 col-lg-8 col-sm-12 ">
                    {loading ? (
                        <div className="text-center">
                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                                <Spinner animation="border" role="status" variant="danger">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </div>
                    ) : (
                        sortedGroupedStores.map(([province, stores]) => {
                            const sortedStores = stores.sort((a, b) => a.adressStore.localeCompare(b.adressStore));
                            return (
                                <Card key={province} className="mb-3">
                                    <Card.Header
                                        className={`text-primary${expandedProvince === province ? ' border-0 bg-danger' : ''}`}
                                        style={{ boxShadow: 'none' }}
                                    >
                                        <Button
                                            variant="link"
                                            className={`btn btn-link${expandedProvince === province ? ' active text-white' : ''}`}
                                            onClick={() => handleProvinceToggle(province)}
                                            style={{ textDecoration: 'none', outline: 'none', boxShadow: 'none' }}
                                            aria-expanded={expandedProvince === province}
                                        >
                                            {province}
                                        </Button>
                                    </Card.Header>
                                    <Collapse in={expandedProvince === province}>
                                        <Card.Body className={`shadow-lg  smooth-collapse ${expandedProvince === province ? 'show' : ''}`}>
                                            {sortedStores.map((store) => (
                                                <div className="shadow-sm px-2 py-1 mt-3" key={store.id}>
                                                    <p className={"mb-0 fw-bolder "}>{store.adressStore}</p>
                                                    <a href={`tel:${store.phone}`} className="text-info"> <InputMask mask="+375 (99) 999-99-99" value={store.phone} className={"ttt"} disabled /></a>
                                                    <div className="col-6"><hr className={"mx-0 px-0 py-0 "}/></div>
                                                    <p className="mb-0">Пн-пт: {store.workTimePnPt}-{store.workTimePnPt2}</p>
                                                    <p className="mb-0">Суббота: {store.workTimeSb}-{store.workTimeSb2}</p>
                                                    <p className="mb-0">Воскресенье: {store.workTimeVs}-{store.workTimeVs2}</p>
                                                    <p className="mb-0">Выходной: {store.workTimeVih}</p>
                                                    <p className="mb-0">Сан. день: {store.workTimeSanDay}</p>

                                                    <hr className="border border-danger border-2 opacity-100 mx-0 px-0 py-0 my-0"/>

                                                    {hasToken && (
                                                        <div className="my-2 mx-2 row d-flex align-items-end flex-end justify-content-between">
                                                            <button
                                                                style={styles.deleteButton}
                                                                className="btn btn-danger"
                                                                onClick={() => handleDelete(store.id)}
                                                            >
                                                                Удалить
                                                            </button>
                                                            <button style={styles.editButton} className="btn btn-success text-white">
                                                                <Link to={`/update/stores/${store.id}`} className="text-decoration-none text-white">
                                                                    Изменить
                                                                </Link>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </Card.Body>
                                    </Collapse>
                                </Card>
                            );
                        })
                    )}
                    {hasToken && (
                        <div>
                            <Button variant="primary" className="me-2">
                                <Link to="/admin/AddStores" className="text-white text-decoration-none col-12">
                                    Добавить магазин
                                </Link>
                            </Button>
                        </div>
                    )}
                </div>
                <div className="col ">
                    <p className={"fs-5 lh-base"}>
                        Добро пожаловать на страницу фирменных магазинов «Мясная лавка»! В настоящее время у нас открыто более 70 магазинов, расположенных в Витебской, Минской и Могилевской областях.
                    </p>

                    <p className={"fs-5 lh-base"}>
                        Мы рады предложить нашим покупателям широкий ассортимент свежей натуральной и готовой продукции из мяса свинины и цыплят-бройлеров в магазинах «Мясная лавка» по доступным ценам. А скидки и акции сделают покупки выгодными и приятными!

                    </p>
                    <p className={"fs-5 lh-base"}>
                        На данной странице вы сможете найти информацию о адресах, режимах работы и контактах наших магазинов в различных областях и регионах Беларуси.
                    </p>

                </div>
            </div>
            <Footer />
        </>
    );
};

export { Stores };
