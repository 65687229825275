import React, {useState} from 'react';
import {WebHeader} from "../../components/Header";
import {Footer} from "../../components/Footer";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTokenValidation} from "../../components/TokenValidation";
import axios from "axios";

const UpdateJobs = () => {
    const [job, setJob] = useState({
        profession:"",
        jobDesc:"",
        salary:"",
        experience:"",
        address:"",
        contactsKADRI:""
    })
    const [showWarning, setShowWarning] = useState(false); // Состояние для показа/скрытия предупреждения
    const [isSubmitClicked, setIsSubmitClicked] = useState(false); // Состояние для отслеживания нажатия кнопки "Добавить"

    const navigate = useNavigate()
    const location= useLocation()

    const jobID = location.pathname.split("/"[2])

    const handleChange = (e)=>{
        setJob((prev)=>({...prev, [e.target.name]:e.target.value}))
    }
    useTokenValidation()

    const handleClick = async e =>{
        e.preventDefault()

        setIsSubmitClicked(true); // Устанавливаем значение isSubmitClicked в true

        if (
            job.profession.trim() === "" ||
            job.jobDesc.trim() === "" ||
            job.salary.trim() === "" ||
            job.experience.trim() === "" ||
            job.address.trim() === "" ||
            job.contactsKADRI.trim() === ""
        ) {
            // Поля не заполнены, выполнение прерывается
            setShowWarning(true)
            return;
        }

        try{
            await axios.put("https://node-server.m-lavka.by/admin/jobs"+jobID, job)
            navigate("/jobs")
        }catch (err) {
            console.log(err)
        }
    }



    return (
        <>
            <div className={"test"}>
                <WebHeader/>
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="form w-50">
                        <h1>Обновление вакансии</h1>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Должность"
                                onChange={handleChange}
                                name="profession"
                                className={`form-control ${isSubmitClicked && (job.profession.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (job.profession.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Описание профессии"
                                onChange={handleChange}
                                name="jobDesc"
                                className={`form-control ${isSubmitClicked && (job.jobDesc.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (job.jobDesc.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="ЗП"
                                onChange={handleChange}
                                name="salary"
                                className={`form-control ${isSubmitClicked && (job.salary.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (job.salary.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Опыт"
                                onChange={handleChange}
                                name="experience"
                                className={`form-control ${isSubmitClicked && (job.experience.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (job.experience.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Адресс"
                                onChange={handleChange}
                                name="address"
                                className={`form-control ${isSubmitClicked && (job.address.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (job.address.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                            <hr/>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    placeholder="Куда звонить? +375295091364, Наталья"
                                    onChange={handleChange}
                                    name="contactsKADRI"
                                    className={`form-control ${isSubmitClicked && (job.contactsKADRI.trim() === "") ? 'is-invalid' : ''}`}
                                />
                                {isSubmitClicked && (job.contactsKADRI.trim() === "") && (
                                    <div className="invalid-feedback">
                                        Поле не может быть пустым
                                    </div>
                                )}
                            </div>
                            <button onClick={handleClick} className="btn btn-warning">Добавить</button>
                            <Link to="/jobs"><button className="btn btn-secondary text-decoration-none">К вакансиям</button></Link>

                        </div>
                    </div>
                </div>
                {/*

                Должность
                Описание
                ЗП
                Опыт
                Адрес
                Контакты

                */}
                <Footer/>
            </div>
        </>
    );
};

export {UpdateJobs};