import React, { useState} from 'react';
import axios from "axios";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "../../style.css"
import {WebHeader} from "../../components/Header";
import {useTokenValidation} from "../../components/TokenValidation";

function UpdateStores() {


    const [store, setStore] = useState({
        adressStore:"",
        phone:""
    })

    const [showWarning, setShowWarning] = useState(false); // Состояние для показа/скрытия предупреждения
    const [isSubmitClicked, setIsSubmitClicked] = useState(false); // Состояние для отслеживания нажатия кнопки "Добавить"

    const navigate = useNavigate()
    const location= useLocation()

    const storeId = location.pathname.split("/"[2])

    const handleChange = (e)=>{
        setStore((prev)=>({...prev, [e.target.name]:e.target.value}))
    }
    useTokenValidation()


    const handleClick = async e =>{
        e.preventDefault()

        setIsSubmitClicked(true); // Устанавливаем значение isSubmitClicked в true

        if (
            store.adressStore.trim() === "" ||
            store.phone.trim() === ""
        ) {
            // Поля не заполнены, выполнение прерывается
            setShowWarning(true)
            return;
        }

        try{
            await axios.put("https://node-server.m-lavka.by/admin/stores"+storeId, store)
            navigate("/stores")
        }catch (err) {
            console.log(err)
        }
    }



    return (
        <div className={"test"}>
            <WebHeader/>
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="form w-50">
                    <h1>1. Внесите информацию о магазине</h1>
                    <div className="mb-3">
                        <select
                            value={store.province}
                            name="province"
                            onChange={handleChange}
                            className="form-select mb-3"
                        >
                            <option value="Не указана">Не указана</option>
                            <option value="Витебск">Витебск</option>
                            <option value="Витебская область">Витебская область</option>
                            <option value="Минская область">Минская область</option>
                            <option value="Могилевская область">Могилевская область</option>
                            <option value="Брестская область">Брестская область</option>
                            <option value="Гомельская область">Гомельская область</option>
                            <option value="Гродненская область">Гродненская область</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Адрес магазина"
                            onChange={handleChange}
                            name="adressStore"
                            className={`form-control ${(isSubmitClicked && (store.adressStore.trim() === "" || store.adressStore.length > 250)) ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && (store.adressStore.length > 100) && (
                            <div className="invalid-feedback">
                                Слишком длинный адрес
                            </div>
                        )}
                        {isSubmitClicked && (store.adressStore.trim() === "") && (
                            <div className="invalid-feedback">
                                Поле не может быть пустым
                            </div>
                        )}
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Телефон"
                            onChange={handleChange}
                            name="phone"
                            className={`form-control ${isSubmitClicked && store.phone.trim() === "" ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && (store.phone.trim() === "") && (
                            <div className="invalid-feedback">
                                Поле не может быть пустым
                            </div>
                        )}
                        {isSubmitClicked && (store.phone.length > 13) && (
                            <div className="invalid-feedback">
                                Введите телефон в формате "+375291234567"
                            </div>
                        )}
                    </div>
                    <div className="col-12 d-flex align-items-end flex-end justify-content-between">
                        <div>
                            Пн-Пт
                            <select
                                value={store.workTimePnPt}
                                onChange={handleChange}
                                name="workTimePnPt"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimePnPt.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="0:00">0:00</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                                <option value="13:00">13:00</option>
                                <option value="14:00">14:00</option>
                                <option value="15:00">15:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="19:00">19:00</option>
                                <option value="20:00">20:00</option>
                                <option value="21:00">21:00</option>
                                <option value="22:00">22:00</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                        {/*                           */}
                        {/*                           */}
                        {/*                           */}
                        {/*                           */}
                        <div>
                            Пн-Пт
                            <select
                                value={store.workTimePnPt2}
                                onChange={handleChange}
                                name="workTimePnPt2"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimePnPt2.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="0:00">0:00</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                                <option value="13:00">13:00</option>
                                <option value="14:00">14:00</option>
                                <option value="15:00">15:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="19:00">19:00</option>
                                <option value="20:00">20:00</option>
                                <option value="21:00">21:00</option>
                                <option value="22:00">22:00</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 d-flex align-items-end flex-end justify-content-between">
                        <div>
                            Суббота
                            <select
                                value={store.workTimeSb}
                                onChange={handleChange}
                                name="workTimeSb"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimeSb.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="0:00">0:00</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                                <option value="13:00">13:00</option>
                                <option value="14:00">14:00</option>
                                <option value="15:00">15:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="19:00">19:00</option>
                                <option value="20:00">20:00</option>
                                <option value="21:00">21:00</option>
                                <option value="22:00">22:00</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                        {/*                */}
                        {/*                */}
                        {/*                */}
                        {/*                */}
                        <div>
                            Суббота
                            <select
                                value={store.workTimeSb2}
                                onChange={handleChange}
                                name="workTimeSb2"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimeSb2.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="0:00">0:00</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                                <option value="13:00">13:00</option>
                                <option value="14:00">14:00</option>
                                <option value="15:00">15:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="19:00">19:00</option>
                                <option value="20:00">20:00</option>
                                <option value="21:00">21:00</option>
                                <option value="22:00">22:00</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 d-flex align-items-end flex-end justify-content-between ">
                        <div>
                            Воскресенье
                            <select
                                value={store.workTimeVs}
                                onChange={handleChange}
                                name="workTimeVs"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimeVs.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="0:00">0:00</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                                <option value="13:00">13:00</option>
                                <option value="14:00">14:00</option>
                                <option value="15:00">15:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="19:00">19:00</option>
                                <option value="20:00">20:00</option>
                                <option value="21:00">21:00</option>
                                <option value="22:00">22:00</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                        {/*                */}
                        {/*                */}
                        {/*                */}
                        {/*                */}
                        <div>
                            Воскресенье
                            <select
                                value={store.workTimeVs2}
                                onChange={handleChange}
                                name="workTimeVs2"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimeVs2.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="0:00">0:00</option>
                                <option value="1:00">1:00</option>
                                <option value="2:00">2:00</option>
                                <option value="3:00">3:00</option>
                                <option value="4:00">4:00</option>
                                <option value="5:00">5:00</option>
                                <option value="6:00">6:00</option>
                                <option value="7:00">7:00</option>
                                <option value="7:00">7:00</option>
                                <option value="8:00">8:00</option>
                                <option value="9:00">9:00</option>
                                <option value="10:00">10:00</option>
                                <option value="11:00">11:00</option>
                                <option value="12:00">12:00</option>
                                <option value="13:00">13:00</option>
                                <option value="14:00">14:00</option>
                                <option value="15:00">15:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="19:00">19:00</option>
                                <option value="20:00">20:00</option>
                                <option value="21:00">21:00</option>
                                <option value="22:00">22:00</option>
                                <option value="23:00">23:00</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div>
                            Выходной
                            <select
                                value={store.workTimeVih}
                                onChange={handleChange}
                                name="workTimeVih"
                                className={`form-select mb-3 ${isSubmitClicked && store.workTimeVih.trim() === "" ? 'is-invalid' : ''}`}
                            >
                                <option value="Понедельник">Понедельник</option>
                                <option value="Вторник">Вторник</option>
                                <option value="Среда">Среда</option>
                                <option value="Четверг">Четверг</option>
                                <option value="Пятница">Пятница</option>
                                <option value="Суббота">Суббота</option>
                                <option value="Воскресенье">Воскресенье</option>

                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Санитарный день"
                            onChange={handleChange}
                            name="workTimeSanDay"
                            className={`form-control ${(isSubmitClicked && (store.workTimeSanDay.trim() === "" || store.adressStore.length > 250)) ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && (store.workTimeSanDay.trim() === "") && (
                            <div className="invalid-feedback">
                                Поле не может быть пустым
                            </div>
                        )}
                    </div>
                    <hr />
                    <div>
                        <h1>2. Нажмите на кнопку "Изменить"</h1>
                    </div>
                    <button onClick={handleClick} className="btn btn-warning">
                        Изменить
                    </button>
                    <Link to="/stores">
                        <button className="btn btn-secondary text-decoration-none mx-2">
                            К магазинам
                        </button>
                    </Link>
                </div>
            </div>
        </div>

    );
}

export {UpdateStores};


//   UpdateCards