import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {WebHeader} from "../../components/Header";
import {useTokenValidation} from "../../components/TokenValidation";

const UserJobs = () => {
    const [userJobs, setUserJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    useTokenValidation();

    useEffect(() => {
        const fetchUserJobs = async () => {
            try {
                const response = await axios.get('https://node-server.m-lavka.by/userJobs');
                setUserJobs(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
                setLoading(false);
            }
        };

        fetchUserJobs();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete('https://node-server.m-lavka.by/userJobs/' + id);
            setUserJobs(userJobs.filter((userJob) => userJob.id !== id));
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <WebHeader/>
            {loading ? (
                <p>Загрузка...</p>
            ) : (
                <Table striped bordered>
                    <thead className="table-dark">
                    <tr>
                        <th>Название вакансии</th>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Год рождения</th>
                        <th>Телефон</th>
                        <th>Опыт</th>
                        <th>Образование</th>
                        <th>Удаление</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userJobs.map((userJob) => (
                        <tr key={userJob.id}>
                            <td>{userJob.profession}</td>
                            <td>{userJob.lastName}</td>
                            <td>{userJob.firstName}</td>
                            <td>{userJob.birthYear}</td>
                            <td>{userJob.phone}</td>
                            <td>{userJob.experience}</td>
                            <td>{userJob.education}</td>
                            <td>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => handleDelete(userJob.id)}
                                >
                                    Удалить
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};

export {UserJobs};
