import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTokenValidation} from "../../components/TokenValidation";
import axios from "axios";
import * as events from "events";
import {WebHeader} from "../../components/Header";

const UpdateActions = () => {
    const [action, setAction] = useState({
        headerOfAction: "",
        textOfAction: "",
        photoOfAction: "",
        dateFrom: "",
        dateTo: ""
    });

    const [isSubmitClicked, setIsSubmitClicked] = useState(false); // Состояние для отслеживания нажатия кнопки "Добавить"
    const [isImageUploading, setIsImageUploading] = useState(false);

    const navigate = useNavigate();
    useTokenValidation();
    const location= useLocation()
    const actionID = location.pathname.split("/"[2])
    const handleChange = (e) => {
        let value = e.target.value;
        if (e.target.name === "dateFrom" || e.target.name === "dateTo") {
            // Форматирование вводимой строки даты
            const [day, month, year] = value.split(".");
            value = `${year}-${month}-${day}`;
        }
        setAction(prev => ({ ...prev, [e.target.name]: value }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setAction(prevState => ({ ...prevState, photoOfAction: file.name }));
    };

    const handleClick = async (e) => {
        e.preventDefault();
        setIsSubmitClicked(true);

        if (
            action.headerOfAction.trim() === "" ||
            action.textOfAction.trim() === "" ||
            action.dateFrom.trim() === "" ||
            action.dateTo.trim() === ""
        ) {
            return;
        }

        const fileInput = document.getElementById('uploadInput');
        const file = fileInput.files[0];

        if (file) {
            setIsImageUploading(true);

            const formData = new FormData();
            formData.append('pictures', file);

            try {
                setTimeout(function() {}, 5000);

                console.log('https://node-server.m-lavka.by/upl', formData)
                setTimeout(function() {}, 5000);
                const response = await axios.post('https://node-server.m-lavka.by/upl', formData);
                console.log(response.data);
                setIsImageUploading(false);
                setAction(action); // Обновление состояния с данными из формы

                try {
                    console.log("https://node-server.m-lavka.by/admin/actions/"+actionID, action)

                    await axios.put("https://node-server.m-lavka.by/admin/actions"+actionID, action);
                    const res = await axios.get("https://node-server.m-lavka.by/actions");
                    navigate("/actions");

                    setAction(res.data); // Обновление состояния после успешного запроса

                } catch (err) {
                    console.log(err);
                }
            } catch (error) {
                console.error(error);
                setIsImageUploading(false);
            }
        } else {
            setAction(action); // Обновление состояния с данными из формы
            try {
                console.log("https://node-server.m-lavka.by/admin/actions/"+actionID, action)

                await axios.put("https://node-server.m-lavka.by/admin/actions"+actionID, action);
                const res = await axios.get("https://node-server.m-lavka.by/actions");
                navigate("/actions");
                setAction(res.data); // Обновление состояния после успешного запроса

            } catch (err) {
                console.log(err);
            }
        }
    };
    console.log(action)

    return (
        <>
            <WebHeader/>

            <div className="row d-flex">

                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="form w-50">
                        <h1 className="d-flex justify-content-center align-items-center ">Обновление акции</h1>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Название акции"
                                onChange={handleChange}
                                name="headerOfAction"
                                className={`form-control ${isSubmitClicked && action.headerOfAction.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && action.headerOfAction.trim() ==="" &&(
                                <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Текст описания акции"
                                onChange={handleChange}
                                name="textOfAction"
                                className={`form-control ${isSubmitClicked && action.textOfAction.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && action.textOfAction.trim() ==="" &&(
                                <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                            )}
                        </div>
                        <h1>2. Загрузите картинку на сайт</h1>
                        <div className="mb-3">
                            <input
                                type="file"
                                onChange={handleImageChange}
                                name="photoOfAction"
                                className="form-control"
                                id="uploadInput"
                            />
                            {isSubmitClicked && action.photoOfAction.trim() ==="" &&(
                                <p className={"invalid-feedback"}>Выберите изображение.</p>
                            )}
                        </div>
                        <hr />
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Дата с"
                                onChange={handleChange}
                                name="dateFrom"
                                className={`form-control ${isSubmitClicked && action.dateFrom.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && action.dateFrom.trim() ==="" &&(
                                <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Дата по"
                                onChange={handleChange}
                                name="dateTo"
                                className={`form-control ${isSubmitClicked && action.dateTo.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && action.dateTo.trim() ==="" &&(
                                <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                            )}
                        </div>
                        <button
                            onClick={handleClick}
                            className="btn btn-warning "
                            disabled={isImageUploading}
                        >
                            {isImageUploading ? 'Загрузка...' : 'Обновить'}
                        </button>
                        <Link to="/actions">
                            <button className="btn btn-secondary text-decoration-none mx-2">
                                К акциям
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export {UpdateActions};