import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";

const Footer = () => {
    const [hasToken, setHasToken] = useState(false);
    const handleLogin = (token) => {
        setHasToken(true);
        localStorage.setItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600', token);

    };

    const handleLogout = () => {
        setHasToken(false);
        localStorage.removeItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
    };

    return (
        <footer className="footer mt-auto py-4 bg-danger text-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <h5 className="h1 text-white">Мясная лавка</h5>
                        <p className="small text-white">
                            Единство – в традициях, профессионализм – в компетентности, надёжность – в верности слову. Вместе открываем новые возможности!
                        </p>
                        <p className="small text-white mb-0">
                            &copy; {new Date().getFullYear()} УП "Торговый дом" ЗАО "Витебскагропродукт". Все права защищены.{' '}
                        </p>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        {/*<h5 className="text-white mb-3">Панель навигации</h5>*/}
                        <ul className="list-unstyled text-white">
                            <li >
                                <Link to={"/"} className={"text-white"}>Главная</Link>
                            </li>
                            <li >
                                <Link to={"/actions"} className={"text-white"}>Акции</Link>
                            </li>
                            <li>
                                <Link to={"/products"} className={"text-white"}>Ассортимент</Link>
                            </li>
                            <li>
                                <Link to={"/stores"} className={"text-white"}>Магазины</Link>

                            </li>
                            <li className={"mb-2"}>
                                <Link to={"/about"} className={"text-white"}>Наши контакты</Link>
                            </li>
                            <li>
                                <a href={"/Политика оператора в отношении обработки персональных данных.pdf"} target={"_blank"} className={"text-white"}>Политика об обработке персональных данных</a>

                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h5 className="text-white mb-3">Наши партнёры</h5>
                        <ul className="list-unstyled text-muted">
                            <li>
                                <a href="https://sveles.by/" target={"_blank"} className={"text-white"}>Славянский Велес</a>
                            </li>
                            <li>
                                <a href="https://mkslav.by/" target={"_blank"} className={"text-white"}>МК Славянский</a>
                            </li>
                            <li>
                                <a href="http://vkp.by/" target={"_blank"} className={"text-white"}>Витконпродукт</a>
                            </li>

                        </ul>
                        {/*<h5 className="text-white mb-0">Политика об обработке</h5>*/}
                        {/*<a href={"/Политика оператора в отношении обработки персональных данных.pdf"} target={"_blank"} className={"text-white"}>Политика об обработке персональных данных</a>*/}


                    </div>
                    <div className="col-lg-4 col-md-6">
                        <p className={"mb-0 mt-0"}>Юридический адрес: Горовца 6, г. Витебск, Республика Беларусь, 210026</p>
                        {/*<hr className={"mt-0 mb-0"}/>*/}
                        <p className={"mb-2"}>Почтовый адрес: Бешенковичское шоссе 44А, г. Витебск, Республика Беларусь, 210516</p>
                        <p>УНП 390185132</p>


                        {/*<a href={"/Политика оператора в отношении обработки персональных данных.pdf"} target={"_blank"} className={"text-white"}>Политика об обработке персональных данных</a>*/}
                    {/*    <h5 className="text-white mb-3">Напишите нам</h5>*/}
                    {/*    <p className="small text-white">*/}
                    {/*        В будущем будет собственный почтовый сервер, который добавит возможность реализовать обратную связь*/}
                    {/*    </p>*/}
                    {/*    <form action="#">*/}
                    {/*        <div className="input-group mb-3">*/}
                    {/*            <input*/}
                    {/*                className="form-control"*/}
                    {/*                type="text"*/}
                    {/*                placeholder="Введите сообщение"*/}
                    {/*                aria-label="Recipient's username"*/}
                    {/*                aria-describedby="button-addon2"*/}
                    {/*            />*/}
                    {/*            <button className="btn btn-primary" id="button-addon2" type="button">*/}
                    {/*                <i className="fas fa-paper-plane"></i>*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    </form>*/}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export { Footer };
