import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { WebHeader } from '../../components/Header';
import '../../style.css';
import { Link } from 'react-router-dom';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { Footer } from "../../components/Footer";

const Actions = () => {
    const [actions, setActions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
        if (token) {
            setHasToken(true);
        }
    }, []);

    useEffect(() => {
        const fetchActions = async () => {
            try {
                const response = await axios.get('https://node-server.m-lavka.by/actions');
                setActions(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        fetchActions();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://node-server.m-lavka.by/actions/${id}`);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    const formatDate = (date) => {
        const options = { day: 'numeric', month: 'numeric' };
        const formattedDate = date.toLocaleDateString('ru-RU', options);
        return formattedDate;
    };

    const handleShowModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="test">
                <WebHeader />
                {loading ? (
                    <div className="text-center">
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                            <Spinner animation="border" role="status" variant="danger">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        {actions.length === 0 ? (
                            <h1 className="d-flex justify-content-center align-items-center">Акций пока что нет, но скоро они обязательно будут!</h1>
                        ) : (
                            <div>
                                <h1 className="d-flex justify-content-center align-items-center ">Акции в мясной лавке!</h1>
                                <div className="row my-2 mx-2 d-flex justify-content-center align-items-center gap-2">
                                    {actions.map((action) => (
                                        <div className="col-xxl-8 col-lg-10 " key={action.id}>
                                            <div className="row">
                                                <div className="col-8">
                                                    <h3 className={"text-danger"}>{action.headerOfAction}</h3>
                                                </div>
                                                <div className="col-4 px-0">
                                                    <p className="text text-black-50 text-sm-end mb-0">
                                                        С {formatDate(new Date(action.dateFrom))}
                                                    </p>
                                                    <p className="text text-black-50 text-sm-end mb-0">ПО {formatDate(new Date(action.dateTo))}</p>
                                                </div>
                                            </div>
                                            {action.photoOfAction && !loading ? (
                                                <div className="image-container" onClick={() => handleShowModal(action.photoOfAction)}>
                                                    <img
                                                        src={`pictures/${action.photoOfAction}`}
                                                        alt="Картинка"
                                                        className="img-fluid rounded border-warning haha"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="text-center">
                                                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                                                        <Spinner animation="border" role="status" variant="danger">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    </div>
                                                </div>
                                            )}
                                            <p>{action.textOfAction}</p>
                                            {hasToken && <div className="my-2 mx-2 row d-flex align-items-end flex-end justify-content-between">
                                                <button
                                                    style={{ width: '100px', height: '40px', marginBottom: '10px' }}
                                                    className="btn btn-danger"
                                                    onClick={() => handleDelete(action.id)}
                                                >
                                                    Удалить
                                                </button>
                                                <button style={{ width: '100px', height: '40px', marginBottom: '10px' }} className="btn btn-success text-white">
                                                    <Link to={`/update/actions/${action.id}`} className="text-decoration-none text-white">
                                                        Изменить
                                                    </Link>
                                                </button>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {hasToken && <div>
                <Button variant="primary" className="me-2">
                    <Link to="/admin/AddActions" className="text-white text-decoration-none">
                        Добавить
                    </Link>
                </Button>
            </div>}

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={`pictures/${selectedImage}`}
                        alt="Картинка"
                        className="img-fluid"
                    />
                </Modal.Body>
            </Modal>

            <Footer />
        </>
    );
};

export { Actions };
