import React from 'react';
import {WebHeader} from "../../components/Header";
import {Footer} from "../../components/Footer";

const HomePage = () => {
    return (
        <>
            <WebHeader/>
            <div className={"test"}>
                <h3 className={"d-flex justify-content-center align-items-center mt-2"}>Добро пожаловать на сайт</h3>
                <div className={" d-flex justify-content-center align-items-center mt-2"}>
                    <h1 className={"d-flex justify-content-center align-items-center text-bg-danger "}>Мясная лавка!</h1>
                </div>
                <div className={"container"}>
                    <img src={'\\pictures\\HomePage1.jpg'} alt={"Изображение"} className={"rounded w-100"}/>
                    <p className={"mt-2 text fs-5 text-justify "} style={{ textAlign: 'justify'  }}>         Унитарное предприятие «Торговый дом» «ЗАО «Витебскагропродукт» - это розничная сеть магазинов «Мясная лавка». В настоящее время у нас открыто более 70 магазинов, расположенных в Витебской, Минской и Могилевской областях. Все магазины сети работают по единому стандарту обслуживания вне зависимости от размера или географического положения торгового объекта. Предприятие стабильно и   динамично развивается и постоянно расширяет географию торговой сети.</p>
                    <p className={"text fs-5"} style={{ textAlign: 'justify' }}>   Наше предприятие входит в состав агропромышленного холдинга «Славянский Велес» на ряду с предприятиями ЗАО «Витебскагропродукт», ООО «Витконпродукт», ООО «Мясокомбинат Славянский», который является одним из ведущих производителей и поставщиком мясной продукции в Республике Беларусь. Специфика деятельности предприятий холдинга включает в себя полный цикл производства «от поля до прилавка», что позволяет реализовывать покупателям магазинов «Мясная лавка» только свежую и качественную мясную продукцию в широком ассортименте по доступным ценам без посредников. </p>
                    <img src={'\\pictures\\testHomeIMG2.jpg'} alt={"Изображение"} className={"rounded w-100"}/>

                    <h1 className={"mt-2"}>Наша миссия</h1>
                    <p className={"text fs-5 col-xl-4 col-lg-4 col-md-8 col-sm-12"} style={{ textAlign: 'justify'  }}>Обеспечение потребности жителей всех регионов страны свежей мясной продукцией высокого качества по доступным ценам.</p>
                    <h1>Наши преимущества</h1>
                    <ul className={"lh-sm "}>
                        <li className={"fs-5"}>удобное расположение магазинов в г. Витебске и регионах страны    </li>
                        <li className={"fs-5"}>ежедневное поступление свежей мясной продукции в магазины с предприятий холдинга    </li>
                        <li className={"fs-5"}>высочайшее качество реализуемой мясной продукции</li>
                        <li className={"fs-5"}>оптимальная себестоимость продукции   </li>
                        <li className={"fs-5"}>доступные цены на полке магазина  </li>
                        <li className={"fs-5"}>выгодные акционные предложения  </li>
                        <li className={"fs-5"}>наши сотрудники — профессионалы своего дела  </li>
                        <li className={"fs-5"}>строгое соблюдение законодательства в деятельности организации. </li>
                    </ul>
                    <div className="col-xl-12 col-sm-12 col-lg-12 d-flex align-items-center justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-sm-6 d-flex align-items-center justify-content-center">
                            <img className="w-100 pe-xl-5" src={'\\pictures\\logo.png'} alt="Logo" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-6">
                            <p className="fs-5 mb-0">Будем рады видеть вас в числе наших постоянных покупателей.</p>
                            <p className="fs-5">С нами жить вкуснее!</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
};

export {HomePage};