import React, { useState} from 'react';
import axios from "axios";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "../../style.css"
import {WebHeader} from "../../components/Header";
import {useTokenValidation} from "../../components/TokenValidation";

function UpdateCards() {


    const [product, setProduct] = useState({
        productName:"",
        description:"",
        category:"",
        barcode:"",
        photo:""
    })

    const [showWarning, setShowWarning] = useState(false); // Состояние для показа/скрытия предупреждения
    const [isSubmitClicked, setIsSubmitClicked] = useState(false); // Состояние для отслеживания нажатия кнопки "Добавить"

    const navigate = useNavigate()
    const location= useLocation()

    const productId = location.pathname.split("/"[2])

    const handleImageChange = (event) => {
        const file = event.target.files[0];


        setProduct(prevState => ({...prevState, photo: file.name}));
    }


    const handleChange = (e)=>{
        setProduct((prev)=>({...prev, [e.target.name]:e.target.value}))
    }
    useTokenValidation()


    const handleClick = async e =>{
        e.preventDefault()

        setIsSubmitClicked(true); // Устанавливаем значение isSubmitClicked в true

        if (
            product.productName.trim() === "" ||
            product.description.trim() === "" ||
            product.category.trim() === "" ||
            product.barcode.trim() === "" ||
            product.photo.trim() === ""
        ) {
            // Поля не заполнены, выполнение прерывается
            return;
        }

        if (isNaN(product.barcode)) {
            // Поле barcode не является числом, показываем предупреждение
            setShowWarning(true);
            return;
        }

        try{
            await axios.put("https://node-server.m-lavka.by/admin/products"+productId, product)
            navigate("/")
        }catch (err) {
            console.log(err)
        }
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        // input.click();

        input.onchange = () => {
            const file = input.files[0];
            const formData = new FormData();
            formData.append('pictures', file);

            const xhr = new XMLHttpRequest();
            xhr.open('PUT', `https://node-server.m-lavka.by/products`);
            xhr.send(formData);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    console.log(xhr.responseText);
                }
            };
        };
    }



    console.log("IMAGE: "+product.photo)
    return (
        <div className={"test"}>
            <WebHeader/>
            <div className={"d-flex justify-content-center align-items-center vh-100"} >
                <div className={"form w-50" }>
                    <h1>Обновление товара</h1>
                    <div className="mb-3">
                        <input type={"text"} placeholder={"Название"} onChange={handleChange} name={"productName"} className={`form-control ${isSubmitClicked && product.productName.trim() === "" ? 'is-invalid' : ''}`} />
                        {isSubmitClicked && product.productName.trim() ==="" &&(
                            <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                        )}
                    </div>
                    <div className="mb-3">
                        <input type={"text"} placeholder={"Описание"} onChange={handleChange} name={"description"} className={`form-control ${isSubmitClicked && product.description.trim() === "" ? 'is-invalid' : ''}`} />
                        {isSubmitClicked && (product.description.length > 250) && (
                            <div className="invalid-feedback">
                                Описание должно содержать не более 250 символов
                            </div>
                        )}
                        {isSubmitClicked && product.description.trim() ==="" &&(
                            <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                        )}
                    </div>
                    <div className="mb-3">
                        <select
                            value={product.category}
                            onChange={handleChange}
                            name="category"
                            className={`form-select mb-3 ${isSubmitClicked && product.category.trim() === "" ? 'is-invalid' : ''}`}
                        >
                            <option value="">Выберите категорию</option>
                            <option value="Готовая продукция из мяса птицы">Готовая продукция из мяса птицы</option>
                            <option value="Готовая продукция из свинины">Готовая продукция из свинины</option>
                            <option value="Мясо птицы">Мясо птицы</option>
                            <option value="Полуфабрикаты из птицы">Полуфабрикаты из птицы</option>
                            <option value="Полуфабрикаты из свинины">Полуфабрикаты из свинины</option>
                            <option value="Субпродукты из птицы">Субпродукты из птицы</option>
                            <option value="Субпродукты из свинины">Субпродукты из свинины</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <select
                            value={product.producer}
                            name="producer"
                            onChange={handleChange}
                            className="form-select mb-3 custom-select"
                        >
                            <option value="Не указан">Выберите производителя</option>
                            <option value="МК Славянский">Славянский мясокомбинат</option>
                            <option value="Витконпродукт">Витконпродукт</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Штрихкод"
                            onChange={handleChange}
                            name="barcode"
                            className={`form-control ${isSubmitClicked && (product.barcode.trim() === "" || isNaN(product.barcode)) ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && (isNaN(product.barcode)) && (
                            <div className="invalid-feedback">
                                Штрихкод должен быть числом
                            </div>
                        )}
                        {isSubmitClicked && product.barcode.trim() ==="" &&(
                            <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                        )}
                    </div>
                    <div className="mb-3">
                        <input type={"file"} onChange={handleImageChange} name={"image"} className="form-control" />
                    </div>
                    <button onClick={handleClick} className="btn btn-warning">Обновить</button>
                    <Link to={"/"}> <button className="btn btn-secondary text-decoration-none " >На главную </button></Link>
                </div>
            </div>
        </div>

    );
}

export default UpdateCards;


//   UpdateCards