import React, { useState } from 'react';
import { WebHeader } from "../../components/Header";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTokenValidation } from "../../components/TokenValidation";
import InputMask from 'react-input-mask';

const AddContacts = () => {
    const [contact, setContact] = useState({
        jobTitle: "",
        phone: "",
        email: "",
        phone2:""
    });
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const navigate = useNavigate();
    useTokenValidation();

    const handleChange = (e) => {
        setContact(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleClick = async (e) => {
        e.preventDefault();
        setIsSubmitClicked(true);

        if (
            contact.jobTitle.trim() === "" ||
            contact.phone.trim() === "" ||
            contact.email.trim() === ""
        ) {
            return;
        }

        // Удаление ненужных символов из номера телефона (маски)
        const phoneWithoutMask = contact.phone.replace(/\D/g, '');
        const phoneWithoutMask2 = contact.phone2.replace(/\D/g, '');

        try {
            await axios.post("https://node-server.m-lavka.by/about", {
                ...contact,
                phone: phoneWithoutMask,
                phone2: phoneWithoutMask2
            });
            navigate("/about");
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="test">
                <WebHeader />
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="form w-50">
                        <h1>Добавление контакта</h1>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Должность"
                                onChange={handleChange}
                                name="jobTitle"
                                className={`form-control ${isSubmitClicked && (contact.jobTitle.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (contact.jobTitle.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <InputMask
                                mask="+375 (212) 99-99-99"
                                placeholder="Городской телефон"
                                onChange={handleChange}
                                name="phone"
                                className={"form-control"}

                                // className={`form-control ${isSubmitClicked && contact.phone.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {/*{isSubmitClicked && (contact.phone.trim() === "") && (*/}
                            {/*    <div className="invalid-feedback">*/}
                            {/*        Поле не может быть пустым*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                        <div className="mb-3">
                            <InputMask
                                mask="+375 (99) 999-99-99"
                                placeholder="Мобильный телефон"
                                onChange={handleChange}
                                name="phone2"
                                className={"form-control"}
                                // className={`form-control ${isSubmitClicked && contact.phone2.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {/*{isSubmitClicked && (contact.phone2.trim() === "") && (*/}
                            {/*    <div className="invalid-feedback">*/}
                            {/*        Поле не может быть пустым*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="e-mail"
                                onChange={handleChange}
                                name="email"
                                className={`form-control ${isSubmitClicked && contact.email.trim() === "" ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (contact.email.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <button onClick={handleClick} className="btn btn-warning">Добавить</button>
                        <Link to="/about"><button className="btn btn-secondary text-decoration-none">К контактам</button></Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddContacts;
