import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddCards from "./pages/admin/AddCards";
import { Products } from "./pages/user/Products";
import UpdateCards from "./pages/admin/UpdateCards";
import { AuthProvider } from 'react-auth-kit'

import "./style.css"
import {NotFoudPage} from "./pages/user/NotFoudPage";
import {Unauthorized} from "./pages/user/Unauthoruzed";
import {Stores} from "./pages/user/Stores";
import AddStores from "./pages/admin/AddStores";
import {Actions} from "./pages/user/Actions";
import {AboutUs} from "./pages/user/AboutUs";
import {AddActions} from "./pages/admin/AddActions";
import {UpdateStores} from "./pages/admin/UpdateStores";
import {UpdateActions} from "./pages/admin/UpdateActions";
import AddContacts from "./pages/admin/AddContacts";
import {JobsPage} from "./pages/user/JobsPage";
import {AddJobs} from "./pages/admin/AddJobs";
import {UserJobs} from "./pages/admin/UserJobs";
import {UpdateContacts} from "./pages/admin/UpdateContacts";
import {UpdateJobs} from "./pages/admin/UpdateJobs";
import {LoginForm} from "./pages/admin/LoginForm";
import {HomePage} from "./pages/user/HomePage";

function App() {
  return (
      <div className="App">
        <AuthProvider
            authType={'cookie'}
            authName={'_auth'}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "https:"}
        >
          <BrowserRouter>
            <Routes>
              <Route path={"/"} element={<HomePage />} />
              <Route path={"/products"} element={<Products />} />
              <Route path={"/stores"} element={<Stores />} />
              <Route path={"/about"} element={<AboutUs />} />
              <Route path={"/jobs"} element={<JobsPage />} />
              <Route path={"/addJobs"} element={<AddJobs />} />
              <Route path={"/userJobs"} element={<UserJobs />} />
              <Route path={"/actions"} element={<Actions/>} />
              <Route path={"/admin/addCards"} element={<AddCards />} />
              <Route path={"/admin/addContact"} element={<AddContacts />} />
              <Route path={"/admin/addStores"} element={<AddStores />} />
              <Route path={"/admin/addActions"} element={<AddActions />} />
              <Route path={"/update/products/:id"} element={<UpdateCards />} />
              <Route path={"/update/actions/:id"} element={<UpdateActions />} />
              <Route path={"/update/stores/:id"} element={<UpdateStores />} />
              <Route path={"/update/contacts/:id"} element={<UpdateContacts />} />
              <Route path={"/update/jobs/:id"} element={<UpdateJobs />} />
              <Route path={"*"} element={<NotFoudPage />} />
              <Route path={"/Unauthorized"} element={<Unauthorized />} />


            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </div>
  );
}

export default App;
