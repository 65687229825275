import React, { useState, useEffect } from "react";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const toggleVisibility = () => {
        if (window.scrollY > 30) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <>
            <div className={" justify-content-end align-item-end d-flex "}>
            {isVisible && (
                <button className="btn btn-outline-danger btn-scroll-to-top my-2 mx-2" onClick={scrollToTop}>
                    <i>↑</i>
                </button>
            )}
            </div>
        </>
    );
};

export {ScrollToTopButton};
