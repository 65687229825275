import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap'
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

const LoginForm = ({ onLogin }) => {
    const [token, setToken]=useState(true)
    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });
    const [open, setOpen] = useState(false);
    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://node-server.m-lavka.by/login', credentials);
            const token = response.data.token;
            if (token) {
                onLogin(token);
            }
        } catch (error) {
            console.error('Ошибка входа в аккаунт:', error);
            setToken(false)
        }
    };
    return (
        <div>
            <button type="button" className="btn btn-outline-danger" onClick={handleOpenModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-person" viewBox="0 0 16 16">
                    <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                </svg>
            </button>
            {open ? (
                <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }} onClick={handleCloseModal}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content" onClick={(event) => event.stopPropagation()}>
                            <div className="modal-header">
                                <h5 className="modal-title">Вход в учётную запись</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                            </div>
                            <form onSubmit={handleSubmit} className={" justify-content-center align-items-center "}>
                                <div className={"d-flex justify-content-center align-items-center mt-2"}>
                                    <label htmlFor="username" className={"form-label"}></label>
                                    <input
                                        type="text"
                                        id="username"
                                        name="username"
                                        value={credentials.username}
                                        onChange={handleChange}
                                        className={"form-control w-75"}
                                        placeholder={"Login"}
                                    />
                                </div>
                                <div className={"d-flex justify-content-center align-items-center mt-2"}>
                                    <label htmlFor="password"></label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={credentials.password}
                                        onChange={handleChange}
                                        className={"form-control w-75 my-2"}
                                        placeholder={"Password"}

                                    />
                                </div>
                                {token ? '':<p className={" text text-danger "}>Ошибка входа в аккаунт, проверьте корректность данных</p>}
                                <div className="modal-footer">
                                    <button type="submit" className={"btn btn-outline-primary"}>Войти</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>


            ) : (
                <></>
            )}
        </div>
    // {/*<div className="form-outline mb-4">*/}
    // {/*    <input type="email" id="form2Example1" className="form-control"/>*/}
    // {/*    <label className="form-label" htmlFor="form2Example1">Email address</label>*/}
    // {/*</div>*/}
    //
    // {/*<!-- Password input -->*/}
    // {/*<div className="form-outline mb-4">*/}
    // {/*    <input type="password" id="form2Example2" className="form-control"/>*/}
    // {/*    <label className="form-label" htmlFor="form2Example2">Password</label>*/}
    // {/*</div>*/}


    );
};

export {LoginForm};
