import React, { useEffect, useState } from 'react';
import { WebHeader } from "../../components/Header";
import axios from "axios";
import '../../style.css';
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import InputMask from 'react-input-mask';

const AboutUs = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);

    useEffect(() => {
        const fetchAllContacts = async () => {
            try {
                const response = await axios.get('https://node-server.m-lavka.by/about');
                setContacts(response.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };

        fetchAllContacts();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete('https://node-server.m-lavka.by/about/' + id);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
        if (token) {
            setHasToken(true);
        }
    }, []);

    const deleteButtonStyle = {
        width: '100px',
        height: '40px',
        marginBottom: '10px',
    };

    const editButtonStyle = {
        width: '100px',
        height: '40px',
        marginBottom: '10px',
    };


    return (
        <>
            <div className="test">
                <WebHeader />
                <div className="container">
                    <div>
                        <h1 className="d-flex justify-content-center align-items-center">О нас</h1>
                        <div>
                            {hasToken && (
                                <div>
                                    <Button variant="primary" className="me-2">
                                        <Link to="/admin/addContact" className="text-white text-decoration-none">
                                            Добавить контакт
                                        </Link>
                                    </Button>
                                </div>
                            )}
                            <div className="my-3">
                                <hr className="my-4"/>
                                    <p className=" fs-5 mb-1">Юридический адрес: Горовца 6, г. Витебск, Республика Беларусь,
                                        210026</p>
                                    <p className=" fs-5 mb-1">Адрес офиса: Бешенковичское шоссе 44А, г. Витебск, Республика
                                        Беларусь, 210516</p>
                                    <p className=" fs-5 mb-1">Время работы офиса: 7:50-16:10, обед: 12:30-12:50</p>
                                    <p className=" fs-5 mb-1"></p>
                                    <p className=" fs-5 mb-1 ">Наша почта: <a
                                        href="mailto:td_zao_vap@rambler.ru" className={"text-decoration-none"}>td_zao_vap@rambler.ru</a></p>
                                <div className={"d-flex align-items-center"}>
                                    <p className={"fs-5 mb-0"}>Instagram</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-instagram" viewBox="0 0 16 16">
                                    <path
                                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                                </svg>
                                <p className={"fs-5 ms-2 mb-0"}>  <a href={"https://www.instagram.com/tdmyasnayalavka/"} className={"text-decoration-none"} target={"_blank"}>@tdmyasnayalavka</a></p>
                                </div>
                            </div>


                            {loading ? (
                                <div className="text-center">
                                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                                        <Spinner animation="border" role="status" variant="danger">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    {contacts.map((contact) => (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mx-auto" key={contact.id}>
                                            <hr className="bg-danger" />
                                            <h3 className="text text-black-50">{contact.jobTitle}</h3>

                                            <p className={"fs-4"}>
                                                Телефон: <br />
                                                <a href={`tel:+375212${contact.phone}`}>
                                                    <InputMask mask="+375 (212) 99-99-99" value={contact.phone} className={"ttt fs-5"} disabled />
                                                </a>
                                            </p>

                                            {hasToken && (
                                                <div className="my-2 mx-2 row d-flex align-items-end flex-end justify-content-between">
                                                    <button
                                                        style={deleteButtonStyle}
                                                        className="btn btn-danger"
                                                        onClick={() => handleDelete(contact.id)}
                                                    >
                                                        Удалить
                                                    </button>
                                                    <button style={editButtonStyle} className="btn btn-success text-white">
                                                        <Link to={`/update/contacts/${contact.id}`} className="text-decoration-none text-white">
                                                            Изменить
                                                        </Link>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export { AboutUs };
