import React, {useState} from 'react';
import {WebHeader} from "../../components/Header";
import {Footer} from "../../components/Footer";
import {Link, useNavigate} from "react-router-dom";
import {useTokenValidation} from "../../components/TokenValidation";
import axios from "axios";

const AddJobs = () => {
    const [newJob, setNewJob] = useState({
        profession: "",
        jobDesc: "",
        salary: "",
        experience: "",
        address: "",
        contactsKADRI:""
    });
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const navigate = useNavigate();
    useTokenValidation();

    const handleChange = (e) => {
        setNewJob(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
    const handleClick = async (e) => {
        e.preventDefault();
        setIsSubmitClicked(true);

        if (
            newJob.profession.trim() === "" ||
            newJob.jobDesc.trim() === "" ||
            newJob.salary.trim() === "" ||
            newJob.experience.trim() === "" ||
            newJob.address.trim() === "" ||
            newJob.contactsKADRI.trim() === ""
        ) {
            return;
        }


        try {
            await axios.post("https://node-server.m-lavka.by/jobs", {
                ...newJob,
            });
            navigate("/jobs");
        } catch (err) {
            console.log(err);
        }
    }
    console.log(newJob)

    return (
        <>
            <div className={"test"}>
            <WebHeader/>
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="form w-50">
                        <h1>Добавление вакансии</h1>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Должность"
                                onChange={handleChange}
                                name="profession"
                                className={`form-control ${isSubmitClicked && (newJob.profession.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (newJob.profession.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Описание профессии"
                                onChange={handleChange}
                                name="jobDesc"
                                className={`form-control ${isSubmitClicked && (newJob.jobDesc.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (newJob.jobDesc.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="ЗП"
                                onChange={handleChange}
                                name="salary"
                                className={`form-control ${isSubmitClicked && (newJob.salary.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (newJob.salary.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Опыт"
                                onChange={handleChange}
                                name="experience"
                                className={`form-control ${isSubmitClicked && (newJob.experience.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (newJob.experience.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                placeholder="Адресс"
                                onChange={handleChange}
                                name="address"
                                className={`form-control ${isSubmitClicked && (newJob.address.trim() === "") ? 'is-invalid' : ''}`}
                            />
                            {isSubmitClicked && (newJob.address.trim() === "") && (
                                <div className="invalid-feedback">
                                    Поле не может быть пустым
                                </div>
                            )}
                            <hr/>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    placeholder="Куда звонить? +375295091364, Наталья"
                                    onChange={handleChange}
                                    name="contactsKADRI"
                                    className={`form-control ${isSubmitClicked && (newJob.contactsKADRI.trim() === "") ? 'is-invalid' : ''}`}
                                />
                                {isSubmitClicked && (newJob.contactsKADRI.trim() === "") && (
                                    <div className="invalid-feedback">
                                        Поле не может быть пустым
                                    </div>
                                )}
                            </div>
                            <button onClick={handleClick} className="btn btn-warning">Добавить</button>
                            <Link to="/jobs"><button className="btn btn-secondary text-decoration-none">К вакансиям</button></Link>

                        </div>
                    </div>
                </div>
                {/*

                Должность
                Описание
                ЗП
                Опыт
                Адрес
                Контакты

                */}
            <Footer/>
            </div>
        </>
    );
};

export {AddJobs};