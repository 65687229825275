import React, { useEffect, useState } from 'react';
import { WebHeader } from "../../components/Header";
import { Footer } from "../../components/Footer";
import axios from "axios";
import { Button, Spinner, Modal, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import "../../style.css"

const JobsPage = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [formData, setFormData] = useState({
        lastName: '',
        firstName: '',
        fatherName: '',
        birthYear: '',
        phone: '',
        experience: '',
        education: '',
    });

    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        // Функция для получения общего количества записей
        const getTotalCount = async () => {
            try {
                const response = await axios.get('https://node-server.m-lavka.by/jobsCount');
                setTotalCount(response.data.totalCount);
            } catch (error) {
                console.error('Ошибка при получении общего количества записей:', error);
            }
        };

        // Вызываем функцию для получения общего количества записей при загрузке компонента
        getTotalCount();
    }, []);


    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                const response = await axios.get('https://node-server.m-lavka.by/jobs');
                setJobs(response.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        };

        fetchAllJobs();
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
        if (token) {
            setHasToken(true);
        }
    }, []);

    const handleOpenModal = (jobId) => {
        setSelectedJobId(jobId);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedJobId(null);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const { lastName, firstName, fatherName, birthYear, phone, experience, education } = formData;
            const jobData = {
                lastName,
                firstName,
                fatherName,
                birthYear,
                phone,
                experience,
                education,
                jobsIdSubmit: selectedJobId,
            };
            await axios.post('https://node-server.m-lavka.by/jobs-submit', jobData);
            setShowModal(false);
            setFormData({
                lastName: '',
                firstName: '',
                fatherName: '',
                birthYear: '',
                phone: '',
                experience: '',
                education: '',
            });
            setSelectedJobId(null);
        } catch (error) {
            console.error('Ошибка отправки данных:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://node-server.m-lavka.by/jobs/${id}`);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };



    return (
        <>
            <WebHeader />
            <div className={"test"}>
                <h1 className="d-flex justify-content-center align-items-center">Актуальные вакансии</h1>
                {loading ? (
                    <div className="text-center">
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                            <Spinner animation="border" role="status" variant="danger">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    </div>
                ) : (
                    <div className="container">
                        {hasToken && <div className="d-flex justify-content-between">
                            <Button className={"btn btn-info"}>

                                <Link
                                    to={"/userJobs"}
                                    className={"text-decoration-none text-white"}
                                >
                                    Входящие резюме
                                    {totalCount > 0 ? ` (${totalCount})` : ''}
                                </Link>
                            </Button>
                            <Button className={"btn btn-warning"}>
                                <Link
                                    className={"text-decoration-none text-white"}
                                    to={"/addJobs"}
                                >
                                    Добавить вакансию
                                </Link>
                            </Button>
                        </div>}
                        {jobs.map((job) => (
                            <div className="col d-flex flex-column justify-content-center" key={job.id}>
                                <div className="bg-white shadow px-2 py-2 my-2">
                                    <div className="d-flex justify-content-between">
                                    <h3 className="text text-sm-start d-inline">Должность: {job.profession}</h3>
                                    <p className={"text-sm-end text-black-50 d-inline mx-2"}>{job.newCurrentDate}</p>
                                    </div>
                                    <p>{job.jobDesc}</p>
                                    <p className="text" dangerouslySetInnerHTML={{ __html: `ЗП: ${job.salary.replace(/\d+/g, "<span class='salary fs-5 fw-bold'>$&</span>")}` }}></p>
                                    <p className="text">Опыт: {job.experience}</p>
                                    <p className="text">Адрес: {job.address}</p>
                                    <hr />
                                    <p className="text mt-0">Обращаться по телефону: {job.contactsKADRI}</p>
                                    <div className="d-flex justify-content-end">
                                        <Button onClick={() => handleOpenModal(job.id)}>Откликнуться</Button>
                                        {hasToken &&
                                            (
                                                <div>
                                                    <Button className={"btn btn-success text-white"}><Link className={"text-decoration-none text-white"} to={`/update/jobs/${job.id}`}> Изменить </Link></Button>
                                                    <Button onClick={() =>
                                                        handleDelete(job.id)}
                                                            className={"btn btn-danger"}>
                                                        Удалить
                                                    </Button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                )}

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Отправить заявку</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="lastName">
                                <Form.Label>Фамилия</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="firstName">
                                <Form.Label>Имя</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fatherName">
                                <Form.Label>Отчество</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="fatherName"
                                    value={formData.fatherName}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="birthYear">
                                <Form.Label>Год рождения</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="birthYear"
                                    value={formData.birthYear}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Телефон</Form.Label>
                                <Form.Control
                                    as={InputMask}
                                    mask="+375 (99) 999-99-99"
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="experience">
                                <Form.Label>Опыт</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="experience"
                                    value={formData.experience}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="education">
                                <Form.Label>Образование</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="education"
                                    value={formData.education}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Отправить
                            </Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <p className={"text justify-content-center"}>Нажимая на кнопку "Отправить", я даю согласие на обработку моих персональных данных для рассмотрения моей кандидатуры на вакантную должность в соответствии с условиями обработки и подтверждаю, что до дачи согласия ознакомился с разъяснением прав, связанных с обработкой, механизмом их реализации, последствиями дачи согласия или отказа в даче согласия, а также с <a href={"/Политика оператора в отношении обработки персональных данных.pdf"} target={"_blank"} color={"red"}>Политикой об обработке персональных данных</a>.</p>
                    </Modal.Footer>
                </Modal>
            </div>
            <Footer />
        </>
    );
};

export { JobsPage };
