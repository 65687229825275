import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import 'bootstrap';
import {LoginForm} from "../pages/admin/LoginForm";

const WebHeader = () => {
    const location = useLocation();
    const [hasToken, setHasToken] = useState(false);
    const handleLogin = (token) => {
        setHasToken(true);
        localStorage.setItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600', token);

    };

    const handleLogout = () => {
        setHasToken(false);
        localStorage.removeItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
    };
    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');

        if (token) {
            setHasToken(true);
        }
    }, [hasToken]);


    return (
        <div className={"sticky-top"}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-danger sticky-top">
                <style>
                    {`
            .navbar-nav .nav-link.active {
              color: green;
            }
          `}
                </style>
                <div className="container-fluid ">
                    <Link className="navbar-brand" to={"/"}>
                        <img src={"logoHeader3.png"} alt="Logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className={`fs-5 nav-item ${location.pathname === '/' ? 'testActiveLink' : ''}`}>
                                <Link className="nav-link" to={"/"}>
                                    Главная
                                </Link>
                            </li>
                            <li className={`fs-5 nav-item ${location.pathname === '/products' ? 'testActiveLink' : ''}`}>
                                <Link className="nav-link" to={"/products"}>
                                    Ассортимент
                                </Link>
                            </li>
                            <li className={`fs-5 nav-item ${location.pathname === '/actions' ? 'testActiveLink' : ''}`}>
                                <Link className="nav-link" to={"/actions"}>
                                    Акции
                                </Link>
                            </li>
                            <li className={`fs-5 nav-item ${location.pathname === '/stores' ? 'testActiveLink' : ''}`}>
                                <Link className="nav-link" to={"/stores"}>
                                    Список магазинов
                                </Link>
                            </li>
                            <li className={`fs-5 nav-item ${location.pathname === '/jobs' ? 'testActiveLink' : ''}`}>
                                <Link className="nav-link" to={"/jobs"}>
                                    Вакансии
                                </Link>
                            </li>
                            <li className={`fs-5 nav-item ${location.pathname === '/about' ? 'testActiveLink' : ''}`}>
                                <Link className="nav-link" to={"/about"}>
                                    Контакты
                                </Link>
                            </li>


                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link disabled" href="/contacts" tabIndex="-1"*/}
                            {/*       aria-disabled="true">Контакты</a>*/}
                            {/*</li>*/}
                        </ul>
                        <div className={"ms-auto"}>

                        {hasToken ? (
                            <button type="button" className="btn btn-outline-danger" onClick={handleLogout}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                    <path
                                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                                </svg>
                                <p className={"d-inline"}>Выйти</p>
                            </button>

                        ) : (
                            <LoginForm onLogin={handleLogin} />
                        )}
                        </div>

                    </div>

                </div>
            </nav>
        </div>
    );
};

export { WebHeader };
