import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function useTokenValidation() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
        if (!token) {
            navigate('/Unauthorized');
        }
        // Дополнительная проверка валидности токена может быть добавлена здесь
    }, [navigate]);
}

export {useTokenValidation}