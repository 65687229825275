import React from 'react';
import 'bootstrap'

const Unauthorized = () => {
    return (
        <div>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">401</h1>
                    <p className="fs-3"><span className="text-danger">Упс!</span> Доступ запрещён</p>
                    <p className="lead">
                        Вы не авторизованы, поэтому доступ запрещен. Попробуйте авторизоваться.
                    </p>
                    <a href="/" className="btn btn-primary">На главную</a>
                </div>
            </div>
        </div>
    );
};

export {Unauthorized};