import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Spinner, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style.css';
import { WebHeader } from '../../components/Header';
import { LoginForm } from '../admin/LoginForm';
import { ScrollToTopButton } from '../../components/ScrollToTop';
import { Footer } from '../../components/Footer';

const Products = () => {
    const [product, setProduct] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [hasToken, setHasToken] = useState(false);
    const [expandedId, setExpandedId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [selectedCategory, setSelectedCategory] = useState('Все');
    const [selectedProducer, setSelectedProducer] = useState(''); // Новое состояние для выбора поставщика
    const [loading, setLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleLogin = (token) => {
        setHasToken(true);
        localStorage.setItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600', token);

    };

    const handleLogout = () => {
        setHasToken(false);
        localStorage.removeItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');
    };

    const styles = {
        deleteButton: {
            width: '100px',
            height: '40px',
            marginBottom: '10px',
        },
        editButton: {
            width: '100px',
            height: '40px',
            marginBottom: '10px',
        },
    };
    useEffect(() => {
        const fetchAllProducts = async () => {
            try {
                const res = await axios.get('https://node-server.m-lavka.by/products');
                if (Array.isArray(res.data)) {
                    setProduct(res.data);
                } else {
                    setProduct([]); // Установить пустой массив или другое значение по умолчанию
                }
                setLoading(false); // Установить loading в false после успешного получения данных
            } catch (err) {
                console.log(err);
                setProduct([]); // Установить пустой массив или другое значение по умолчанию в случае ошибки
                setLoading(false); // Установить loading в false после ошибки
            }
        };
        fetchAllProducts();
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('5d75d2b659428623d79d2704f9a0f029fb480d2e4ba3a8ec79e3c66d8bc15718931877335ee49814c1c1d202e0eb93fa958296aaa0a53ac034a0759aea7de600');

        if (token) {
            setHasToken(true);
        }
    }, []);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete('https://node-server.m-lavka.by/products/' + id);
            window.location.reload();
        } catch (err) {
            console.log(err);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };
    const handleProducerChange = (event) => {
        setSelectedProducer(event.target.value);
    };

    const filteredProducts = product.filter((product) => {
        const lowercaseQuery = searchQuery.toLowerCase();
        const lowercaseCategory = selectedCategory.toLowerCase();
        const lowercaseProducer = selectedProducer.toLowerCase();

        return (
            (selectedCategory === 'Все' || product.category.toLowerCase() === lowercaseCategory) &&
            (product.productName.toLowerCase().includes(lowercaseQuery) ||
                product.description.toLowerCase().includes(lowercaseQuery)) &&
            (selectedProducer === '' || product.producer.toLowerCase() === lowercaseProducer)
        );
    });

    const sortedProducts = filteredProducts.slice().reverse();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedProducts.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(sortedProducts.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="test">
            <WebHeader />
            <h1 className="d-flex justify-content-center align-items-center mb-5">Ассортимент продукции</h1>
            {/*{hasToken ? (*/}
            {/*    <div className="my-2 mx-2">*/}
            {/*        <h1>Режим администратора</h1>*/}
            {/*        <button onClick={handleLogout} className="btn btn-outline-danger">*/}
            {/*            Выйти*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*) : (*/}
            {/*    <LoginForm onLogin={handleLogin} />*/}
            {/*)}*/}
            <div className="container">
                <div className="row">
                    <div className="col col-4">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearch}
                            className="form-control"
                            placeholder="Поиск..."
                        />
                    </div>
                    <div className="col col-4">
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            className="form-select mb-3"
                        >
                            <option value="Все">Категория</option>
                            <option value="Готовая продукция из мяса птицы">Готовая продукция из мяса птицы</option>
                            <option value="Готовая продукция из свинины">Готовая продукция из свинины</option>
                            <option value="Мясо птицы">Мясо птицы</option>
                            <option value="Полуфабрикаты из птицы">Полуфабрикаты из птицы</option>
                            <option value="Полуфабрикаты из свинины">Полуфабрикаты из свинины</option>
                            <option value="Субпродукты из птицы">Субпродукты из птицы</option>
                            <option value="Субпродукты из свинины">Субпродукты из свинины</option>
                        </select>
                    </div>
                    <div className="col col-4">
                        <select
                            value={selectedProducer}
                            onChange={handleProducerChange}
                            className="form-select mb-3"
                        >
                            <option value="">Поставщик</option>
                            <option value="МК Славянский">Славянский мясокомбинат</option>
                            <option value="Витконпродукт">Витконпродукт</option>
                        </select>
                    </div>
                    <div className="col-4 my-2">
                        {hasToken ? (
                            <Button variant="primary">
                                <Link to="/admin/addCards" className="text-white text-decoration-none">
                                    Добавить
                                </Link>
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {loading ? (
                    <div className="text-center">
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                            <Spinner animation="border" role="status" variant="danger">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 border">
                            {currentItems.length > 0 ? (
                                currentItems.map((product) => (
                                    <div className="row col-12 col-sm-6 col-md-4 col-lg-3 mx-0 border border-danger" key={product.id}>
                                        <div className="">
                                            {product.photo ? (
                                                <img
                                                    src={'\\pictures\\' + product.photo}
                                                    alt="Картинка"
                                                    className="rounded mx-auto d-block my-2 img-fluid border-warning testCustomImage"
                                                    onClick={() => handleImageClick(`\\pictures\\${product.photo}`)}
                                                />
                                            ) : (
                                                <p>нет картинки</p>
                                            )}
                                            <h2 className="text-sm-start">{product.productName}</h2>
                                            <p className={`truncate-multiline1 ${expandedId === product.id ? 'expanded1' : ''}`}>
                                                {product.description}
                                            </p>
                                        </div>
                                        <div className="row d-flex align-items-end flex-end mt-auto align-self-end ">
                                            <hr className="my-2" />
                                            <div className="d-flex justify-content-between">
                                                <p id="categorise" className="text-sm-start d-inline me-xxl-5">
                                                    {product.category}
                                                </p>
                                                <p id="producer" className="text-sm-end text-black-50 d-inline mx-2">
                                                    {product.producer}
                                                </p>
                                            </div>
                                            {hasToken && (
                                                <div className="my-2 mx-2 row d-flex align-items-end flex-end justify-content-between">
                                                    <button
                                                        style={styles.deleteButton}
                                                        className="btn btn-danger"
                                                        onClick={() => handleDelete(product.id)}
                                                    >
                                                        Удалить
                                                    </button>
                                                    <button style={styles.editButton} className="btn btn-success text-white">
                                                        <Link
                                                            to={`/update/products/${product.id}`}
                                                            className="text-decoration-none text-white"
                                                        >
                                                            Изменить
                                                        </Link>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="col d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
                                    <h1>Похоже товаров с заданными фильтрами нет...</h1>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div className={"col d-flex justify-content-end "}>Показано товаров: {currentItems.length} из {product.length}</div>

                <div className="pagination justify-content-center">

                    <ul className="my-2 pagination justify-content-center">

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                <a
                                    onClick={() => paginate(number)}
                                    href="#"
                                    className={`page-link ${number === currentPage ? 'active' : ''}`}
                                >
                                    {number}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <img src={selectedImage} alt="Картинка" className="img-fluid" />
                </Modal.Body>
            </Modal>
            <ScrollToTopButton />
            <Footer />
        </div>
    );
};

export { Products };
