import React, { useEffect, useState } from 'react';
import axios from "axios";
import 'bootstrap'

import { Link, useNavigate } from "react-router-dom";
import "../../style.css"
import { WebHeader } from "../../components/Header";
import {useTokenValidation} from "../../components/TokenValidation";

function AddCards() {
    const [product, setProduct] = useState({
        productName: "",
        description: "",
        category: "",
        producer: "",
        barcode: "",
        photo: ""
    });

    const [showWarning, setShowWarning] = useState(false); // Состояние для показа/скрытия предупреждения
    const [isSubmitClicked, setIsSubmitClicked] = useState(false); // Состояние для отслеживания нажатия кнопки "Добавить"
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setProduct(prevState => ({ ...prevState, photo: file.name }));
    }
    useTokenValidation()

    const handleChange = (e) => {
        setProduct(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleClick = async (e) => {
        e.preventDefault();

        setIsSubmitClicked(true); // Устанавливаем значение isSubmitClicked в true

        if (
            product.productName.trim() === "" ||
            product.description.trim() === "" ||
            product.category.trim() === "" ||
            product.barcode.trim() === "" ||
            product.photo.trim() === "" ||
            product.description.length > 250 ||
            isNaN(product.barcode)
        ) {
            // Некорректно заполнены поля, выполнение прерывается
            return;
        }

        try {

            await axios.post("https://node-server.m-lavka.by/products", product); // Добавление карточки с изображением на сервер

            navigate("/"); // Перенаправление на главную страницу
        } catch (err) {
            console.log(err);
        }
        const fileInput = document.getElementById('uploadInput');
        const file = fileInput.files[0];

        const formData = new FormData();
        formData.append('pictures', file);

        try {
            const response = await axios.post('https://node-server.m-lavka.by/upl', formData);
            console.log(response.data); // Ответ сервера в формате JSON
        } catch (error) {
            console.error(error);
        }
    }
    console.log(product)
    return (
        <div className="test">
            <WebHeader />
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="form w-50">
                    <h1>1. Внесите информацию о товаре</h1>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Название"
                            onChange={handleChange}
                            name="productName"
                            className={`form-control ${isSubmitClicked && product.productName.trim() === "" ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && product.productName.trim() ==="" &&(
                            <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                        )}
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Описание"
                            onChange={handleChange}
                            name="description"
                            className={`form-control ${(isSubmitClicked && (product.description.trim() === "" || product.description.length > 250)) ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && (product.description.length > 250) && (
                            <div className="invalid-feedback">
                                Описание должно содержать не более 250 символов
                            </div>
                        )}
                        {isSubmitClicked && product.description.trim() ==="" &&(
                            <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                        )}
                    </div>
                    <div className="mb-3">
                        <select
                            value={product.category}
                            onChange={handleChange}
                            name="category"
                            className={`form-select mb-3 ${isSubmitClicked && product.category.trim() === "" ? 'is-invalid' : ''}`}
                        >
                            <option value="">Выберите категорию</option>
                            <option value="Готовая продукция из мяса птицы">Готовая продукция из мяса птицы</option>
                            <option value="Готовая продукция из свинины">Готовая продукция из свинины</option>
                            <option value="Мясо птицы">Мясо птицы</option>
                            <option value="Полуфабрикаты из птицы">Полуфабрикаты из птицы</option>
                            <option value="Полуфабрикаты из свинины">Полуфабрикаты из свинины</option>
                            <option value="Субпродукты из птицы">Субпродукты из птицы</option>
                            <option value="Субпродукты из свинины">Субпродукты из свинины</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <select
                            value={product.producer}
                            name="producer"
                            onChange={handleChange}
                            className="form-select mb-3 custom-select"
                        >
                            <option value="Не указан">Выберите производителя</option>
                            <option value="МК Славянский">Славянский мясокомбинат</option>
                            <option value="Витконпродукт">Витконпродукт</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Штрихкод"
                            onChange={handleChange}
                            name="barcode"
                            className={`form-control ${isSubmitClicked && (product.barcode.trim() === "" || isNaN(product.barcode)) ? 'is-invalid' : ''}`}
                        />
                        {isSubmitClicked && (isNaN(product.barcode)) && (
                            <div className="invalid-feedback">
                                Штрихкод должен быть числом
                            </div>
                        )}
                        {isSubmitClicked && product.barcode.trim() ==="" &&(
                            <p className={"invalid-feedback"}>Поле не может быть пустым.</p>
                        )}
                    </div>
                    <hr />
                    <h1>2. Загрузите картинку на сайт</h1>
                    <div className="mb-3">
                        <input
                            type="file"
                            onChange={handleImageChange}
                            name="photo"
                            className="form-control"
                            id={"uploadInput"}
                        />
                    </div>
                    <hr/>
                    <div>
                        <h1>3. Нажмите на кнопку "Добавить"</h1>
                    </div>
                    <button onClick={handleClick} className="btn btn-warning">
                        Добавить
                    </button>
                    <Link to="/">
                        <button className="btn btn-secondary text-decoration-none mx-2">
                            На главную
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AddCards;
